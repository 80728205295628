<template>
  <basic-layout v-loading="loading">
    <template v-slot:content-main>
      <div class="award-page">
        <div class="award-container">
          <div
            class="award-groups"
            v-for="(item, index) in groupDatas"
            :key="index"
          >
            <div class="award-group-title">{{ item.name }}</div>
            <div class="award-list">
              <animate-card
                v-for="(data, index) in item.children"
                :key="data.id"
                :name="data.name"
                :image="data.cover_url + '?x-oss-process=style/h200'"
                :slug="data.id"
                :index="index"
                @click="handleClickCard(data)"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:content-side>
      <div class="sideData-section">
        <p class="sideData-title">{{ sideData.name }}</p>
        <div class="sideData-content" v-html="sideData.content"></div>
      </div>
    </template>
  </basic-layout>
</template>

<script>
import BasicLayoutBody from "@/components/Layout/BasicLayoutBody";
import AnimateCard from "@/components/AnimateCard";
import workService from "@/globals/service/work";

export default {
  components: {
    "basic-layout": BasicLayoutBody,
    "animate-card": AnimateCard
  },
  data() {
    return {
      loading: true,
      sideData: {},
      awards: []
    };
  },
  computed: {
    groupDatas() {
      return [
        {
          name: "媒体报道",
          value: 1,
          children: this.awards.filter(data => data.type === 1)
        },
        {
          name: "获奖荣耀",
          value: 2,
          children: this.awards.filter(data => data.type === 2)
        }
      ];
    }
  },
  created() {
    workService
      .awards({ page_size: 100 })
      .then(res => {
        this.awards = res.list;
        this.sideData = res.list[0];
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    handleClickCard(item) {
      this.sideData = item;
    }
  }
};
</script>

<style type="text/css" lang="less" scoped>
.award-page {
  padding-left: 20px;

  .award-container {
    .award-groups {
      margin-bottom: 36px;
      .award-group-title {
        margin-bottom: 4px;
        font-size: 14px;
        font-weight: 600;
      }
      .award-list {
        font-size: 0;
      }
    }
  }
}
.sideData-section {
  .sideData-title {
    font-size: 14px;
    font-weight: bold;
    line-height: 3;
    margin-bottom: 45px;
    font-weight: bold;
  }
  .sideData-link {
    color: #000000;
    background-color: #ffff00;
    font-size: 12px;
    transition: all 0.2s ease-out;
    font-weight: bold;
  }
  .sideData-image {
    margin-bottom: 5px;
    max-width: 100%;
    height: auto;
  }
}

@media (max-width: 750px) {
  .award-page {
    padding: 20 / @ppr 25 / @ppr;
  }
  .sideData-section {
    padding: 0 25 / @ppr;
  }
}
</style>
