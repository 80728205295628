const workNavData = [
  {
    name: "业态/Format",
    value: "format",
    children: [
      { name: "餐饮/F&B", value: "fb" },
      { name: "服饰/Apparel", value: "apparel" },
      { name: "亲子/Parent-child", value: "parent-child" },
      { name: "零售/Retail", value: "retail" },
      { name: "企业/Enterprise", value: "enterprise" },
      { name: "娱乐/Entertainment", value: "entertainment" },
      { name: "摄影/Photo Studio", value: "photo-studio" }
    ]
  },
  {
    name: "位置/Location",
    value: "location",
    children: [
      { name: "上海", value: "shanghai" },
      { name: "北京", value: "beijing" },
      { name: "南京", value: "nanjing" },
      { name: "义乌", value: "yiwu" },
      { name: "苏州", value: "suzhou" },
      { name: "其他", value: "other" }
    ]
  },
  {
    name: "时间/Time",
    value: "time",
    children: [
      { name: "2012", value: "进行中" },
      { name: "2011", value: "2011" },
      { name: "2010", value: "2010" },
      { name: "2019", value: "2019" },
      { name: "2018", value: "2018" },
      { name: "2017", value: "2017" },
      { name: "2016", value: "2016" },
      { name: "2015", value: "2015" }
    ]
  },
  {
    name: "类型/Typology",
    value: "typology",
    children: [
      { name: "商业/Commercial", value: "commercial" },
      { name: "办公/Office", value: "office" },
      { name: "居住/Residential", value: "residential" },
      { name: "平面/Graphic", value: "graphic" },
      { name: "产品/Products", value: "products" }
    ]
  }
];

export default workNavData;
