const PREFIX_API = process.env.VUE_APP_API;

export default {
  userInfo: `${PREFIX_API}/api/web/user/user-info`,
  workCats: `${PREFIX_API}/api/web/work-cat`,
  workCat: id => `${PREFIX_API}/api/web/work-cat/${id}`,
  works: `${PREFIX_API}/api/web/works`,
  workDetail: id => `${PREFIX_API}/api/web/works/${id}`,
  workRecommend: `${PREFIX_API}/api/web/work-recommend`,
  workFormats: `${PREFIX_API}/api/web/work-formats`,
  workLocations: `${PREFIX_API}/api/web/work-locations`,
  awards: `${PREFIX_API}/api/web/awards`,
  awardDetail: id => `${PREFIX_API}/api/web/awards/${id}`,
  teamMembers: `${PREFIX_API}/api/web/team-members`,
  teamMemberDetail: id => `${PREFIX_API}/api/web/team-members/${id}`
};
