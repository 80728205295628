<template>
  <basic-layout v-loading="loading">
    <template v-slot:content-main>
      <div class="work-page">
        <div class="recommand-list">
          <div
            class="recommand-item"
            v-for="item in recommends"
            :key="item.id"
            @click="handleCheckoutWork(item.id)"
            v-animate="'active'"
          >
            <div class="recommand-image-wrapper">
              <div
                class="recommand-image-container"
                :style="{
                  backgroundImage:
                    'url(' + item.cover_url + getWaterMarkQuery('w650h440'),
                  backgroundSize: 'cover',
                  backgroundPosition: '50%'
                }"
              >
                <img
                  class="recommand-image"
                  v-if="false"
                  :src="item.cover_url + getWaterMarkQuery('w650h440')"
                />
              </div>
            </div>
            <div class="recommand-mask">
              <div class="recommand-mask-text">
                <div class="recommand-mask-name">{{ item.name }}</div>
                <div class="recommand-mask-category">{{ item.name_en }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </basic-layout>
</template>

<script>
import BasicLayoutBody from "@/components/Layout/BasicLayoutBody";
import workService from "@/globals/service/work";
import { getWaterMarkQuery } from "src/const/watermark";
export default {
  components: {
    "basic-layout": BasicLayoutBody
  },
  data() {
    return {
      loading: true,
      recommends: []
    };
  },
  created() {
    this.getData();
  },
  watch: {
    ["$route"](val, oldVal) {
      if (val.query.cat_id !== oldVal.query.cat_id) {
        this.getData();
      }
    }
  },
  methods: {
    getWaterMarkQuery,
    getData() {
      let catId = this.$route.query.cat_id || 1;
      workService
        .works({
          cat_id: catId,
          page_size: 100
        })
        .then(worksRes => {
          this.recommends = worksRes.list;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleCheckoutWork(slug) {
      this.$router.push({
        name: "WorkDetail",
        params: {
          slug: slug
        }
      });
    }
  }
};
</script>

<style type="text/css" lang="less" scoped>
.recommand-list {
  padding: 25 / @ppr 25 / @ppr;

  .recommand-item {
    position: relative;
    margin-bottom: 63 / @ppr;
    &.active {
      .recommand-mask {
        opacity: 1;
      }
      .recommand-image-container {
        width: 100%;
        filter: blur(3 / @ppr);
        overflow: hidden;
      }
    }
  }
  .recommand-image-wrapper {
    width: 100%;
    height: 325 / @ppr;
    font-size: 0;
    overflow: hidden;
    .recommand-image-container {
      width: 100%;
      height: 100%;
      font-size: 0;
      .recommand-image {
        width: 100%;
        height: 220 / @ppr;
        position: relative;
      }
    }
  }
  .recommand-mask {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    color: #000000;
    opacity: 0;
    transition: opacity 0.5s;
    // backdrop-filter: blur(12 / @ppr);
    .recommand-mask-text {
      width: 100%;
      text-align: center;
      font-weight: 600;
      font-size: 32 / @ppr;
      line-height: 1;
      .recommand-mask-category {
        margin-top: 3 / @ppr;
        line-height: 17 / @ppr;
        font-size: 12 / @ppr;
        font-weight: 400;
      }
    }
  }
}
</style>
