<template>
  <basic-layout v-loading="loading">
    <template v-slot:content-main>
      <div class="work-page">
        <div>业态：{{ work.category }}</div>
        <div class="work-content" v-html="work.content"></div>
        <div class="work-detail">
          <div>
            <p v-if="false">地点：{{ work.location_zh }}</p>
            <p>面积：{{ work.area }} sqm.</p>
            <p>业态：{{ work.category }}</p>
            <p>
              状态：{{
                PROGRESS[work.progress_id] ? PROGRESS[work.progress_id].zh : ""
              }}
            </p>
            <p>时间：{{ formatDate(work.date) }}</p>
            <p v-if="work.city">城市：{{ work.city }}</p>
            <p>主创：{{ work.creator }}</p>
          </div>
          <div class="mt-40">
            <p>#</p>
            <p v-if="false">Loction：{{ work.location_en }}</p>
            <p>Area：{{ work.area }} sqm.</p>
            <p>Format：{{ work.category_en }}</p>
            <p>
              Status{{
                PROGRESS[work.progress_id] ? PROGRESS[work.progress_id].en : ""
              }}
            </p>
            <p v-if="work.city_en">City：{{ work.city_en }}</p>
            <p>Timeframe：{{ formatDate(work.date) }}</p>
          </div>
        </div>
      </div>
    </template>
  </basic-layout>
</template>

<script>
import BasicLayoutBody from "@/components/Layout/BasicLayoutBody";
import workService from "@/globals/service/work";
import dayjs from "dayjs";
import { PROGRESS } from "@/utils/const";

export default {
  components: {
    "basic-layout": BasicLayoutBody
  },
  data() {
    return {
      PROGRESS,
      loading: true,
      filterType: "format",
      slug: null,
      work: {}
    };
  },
  watch: {
    $route: function() {
      this.getData();
    }
  },
  created() {
    this.getData();
  },
  beforeDestroy() {
    this.$store.commit("UPDATE_WORKTITLE", "");
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format("YYYY");
    },
    getData() {
      this.slug = this.$route.params.slug;
      workService
        .workDetail(this.slug)
        .then(work => {
          this.work = work;
          this.$store.commit("UPDATE_WORKTITLE", work.name);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleCheckoutWorkFilterType(value) {
      this.filterType = value;
    },
    handleClickCard(slug) {
      this.handleCheckoutWork(slug);
    },
    handleCheckoutWork(slug) {
      if (slug !== this.slug) {
        this.$router.push({
          name: "WorkDetail",
          params: {
            slug: slug
          },
          query: {
            type: this.filterType
          }
        });
      }
    }
  }
};
</script>

<style type="text/css" lang="less" scoped>
.work-page {
  padding: 0 25 / @ppr;
}
.work-content {
  padding-bottom: 40px;
  margin-top: 15px;
}

.mt-40 {
  margin-top: 40px;
}
</style>
