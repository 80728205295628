import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    userInfo: {},
    deviceWidth: null,
    workTitle: ""
  },
  getters: {
    deviceMode: state => {
      if (!state.deviceWidth) return;
      return state.deviceWidth <= 750 ? "m" : "pc";
    }
  },
  mutations: {
    USERINFO(state, userInfo) {
      state.userInfo = userInfo;
    },
    UPDATE_DEVICEWIDTH(state, deviceWidth) {
      state.deviceWidth = deviceWidth;
    },
    UPDATE_WORKTITLE(state, workTitle) {
      state.workTitle = workTitle;
    }
  },
  actions: {},
  modules: {}
});
