export const PROGRESS = {
  1: {
    zh: "设计中",
    en: "In progress"
  },
  2: {
    zh: "设计完成",
    en: "Design Completed"
  },
  3: {
    zh: "建设中",
    en: "Construction in progress"
  },
  4: {
    zh: "建设完成",
    en: "Constructed"
  }
};
