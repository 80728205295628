<template>
  <div id="app" ref="app">
    <router-view />
  </div>
</template>

<script type="text/javascript">
import debounce from "lodash/debounce";
import { addListener, removeListener } from "resize-detector";

export default {
  data() {
    return {};
  },
  created() {
    this.resize = debounce(this.resize, 100);
    this.resize();
  },
  mounted() {
    addListener(this.$refs.app, this.resize);
  },
  beforeDestroy() {
    removeListener(this.$refs.app, this.resize);
  },
  methods: {
    resize() {
      const width = this.$refs.app.offsetWidth;
      const rem = width / 10; // 将屏幕宽度分成10份， 1份为1rem
      document.documentElement.style.fontSize =
        width >= 750 ? "12px" : `${rem}px`;
      this.$store.commit("UPDATE_DEVICEWIDTH", width);
    }
  }
};
</script>
