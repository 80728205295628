import Store from "@/store/index";
import { xOssProcessWithQuery } from "utils/alioss-process-sdk";

const watermarkAction = {
  h200: {
    format: "jpg",
    interlace: 1,
    resize: {
      type: "m_fill",
      width: 200,
      height: 200
    }
  },
  daylab_index_wh1000: {
    format: "jpg",
    interlace: 1,
    resize: {
      type: "m_fill",
      width: 1280,
      height: 1280
    }
  },
  w650h440: {
    format: "jpg",
    interlace: 1,
    resize: {
      type: "m_fill",
      width: 650,
      height: 650
    }
  },
  w1280: {
    format: "jpg",
    interlace: 1,
    resize: {
      type: "m_lfit",
      width: 1280
    }
  }
};

export const getWaterMarkQuery = key => {
  const userName = Store.state.userInfo?.nickname;
  const sessionKey = key.indexOf("USERNAME") >= 0 ? `${userName}_${key}` : key;
  const cache = sessionStorage.getItem(sessionKey);
  if (cache) return cache;
  const waterParams =
    typeof watermarkAction[key] === "function"
      ? watermarkAction[key]()
      : watermarkAction[key];
  if (waterParams) {
    const value = xOssProcessWithQuery(waterParams);
    sessionStorage.setItem(sessionKey, value);
    return value;
  }
  return "";
};
