import BasicLayout from "@/components/Layout/BasicLayout.vue";
import Work from "@/views/Work/index.vue";
import WorkDetail from "@/views/WorkDetail/index.vue";
import Awards from "@/views/Awards.vue";
import Service from "@/views/Service.vue";
import Team from "@/views/Team.vue";
import Join from "@/views/Join.vue";
import Contact from "@/views/Contact.vue";

const routes = [
  {
    path: "/",
    name: "Root",
    component: BasicLayout,
    redirect: { name: "Works" },
    children: [
      {
        path: "/works",
        name: "Works",
        component: Work,
        meta: {
          title: "作品展示/Works"
        }
      },
      {
        path: "/works/:slug",
        name: "WorkDetail",
        component: WorkDetail,
        meta: {
          title: "作品展示/Works"
        }
      },
      {
        path: "/service",
        name: "Service",
        component: Service,
        meta: {
          title: "甲方需知/Service"
        }
      },
      {
        path: "/awards",
        name: "Awards",
        component: Awards,
        meta: {
          title: "荣誉报道/Awards"
        }
      },
      {
        path: "/team",
        name: "Team",
        component: Team,
        meta: {
          title: "斗西成员/Team"
        }
      },
      {
        path: "/join",
        name: "Join",
        component: Join,
        meta: {
          title: "加入我们/Join"
        }
      },
      {
        path: "/contact",
        name: "Contact",
        component: Contact,
        meta: {
          title: "联系方式/Contact"
        }
      }
    ]
  }
];

export default routes;
