import request from "@/globals/request/axios.js";
import API from "@/globals/request/api.js";

const workService = {
  workCats: function(params = []) {
    return request.get(API.workCats, params);
  },
  workCat: function(id) {
    return request.get(API.workCat(id));
  },
  works: function(params = []) {
    return request.get(API.works, params);
  },
  workDetail: function(id, params = []) {
    return request.get(API.workDetail(id), params);
  },
  workRecommend: function(params = []) {
    return request.get(API.workRecommend, params);
  },
  awards: function(params = []) {
    return request.get(API.awards, params);
  },
  awardDetail: function(id, params = []) {
    return request.get(API.awardDetail(id), params);
  },
  teamMembers: function(params = []) {
    return request.get(API.teamMembers, params);
  },
  teamMemberDetail: function(id, params = []) {
    return request.get(API.teamMemberDetail(id), params);
  },
  workFormats: function(params = []) {
    return request.get(API.workFormats, params);
  },
  workLocations: function(params = []) {
    return request.get(API.workLocations, params);
  }
};

export default workService;
