import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import IconSvg from "./plugins/icons";
import "./plugins/element";
import "@/styles/reset.less";
import "@/styles/common.less";
import "@/styles/theme/index.css";
import "swiper/swiper-bundle.css";
import VueAwesomeSwiper from "vue-awesome-swiper";

Vue.use(VueAwesomeSwiper);
Vue.use(IconSvg);
// Vue.use(ElementUI);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

// 禁用鼠标右键
document.oncontextmenu = function() {
  event.returnValue = false;
};

Vue.directive("animate", {
  bind: function(el, binding) {
    const getOffsetTop = obj => {
      let tmp = obj.offsetTop;
      let val = obj.offsetParent;
      while (val != null) {
        tmp += val.offsetTop;
        val = val.offsetParent;
      }
      return tmp;
    };
    binding.addClass = () => {
      Vue.prototype.$nextTick(() => {
        const { height } = el.getBoundingClientRect();
        if (!binding.top) {
          binding.top = getOffsetTop(el);
        }
        const top = binding.top;
        const h = window.innerHeight;
        if (top > window.scrollY && top + height < window.scrollY + h) {
          if (el.className.indexOf(binding.value) == -1) {
            el.className = binding.value + " " + el.className;
          }
        } else {
          if (el.className.indexOf(binding.value) > -1) {
            let className = el.className.split(" ");
            className.splice(className.indexOf(binding.value), 1);
            el.className = className.join(" ");
          }
        }
      });
    };
    window.addEventListener("scroll", binding.addClass, true);
    binding.addClass();
  },
  unbind: function(el, binding) {
    if (binding.addClass) {
      window.removeEventListener("scroll", binding.addClass, true);
    }
  }
});

// /* eslint-disable */
// (function resize(doc, win) {
//   if (!isPC()) {
//     const docEl = doc.documentElement;
//     const resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize';
//     const recalc = function recalc() {
//       const { clientWidth } = docEl;
//       if (!clientWidth) return;
//       const rem = clientWidth / 10; // 将屏幕宽度分成10份， 1份为1rem
//       docEl.style.fontSize =
//         width >= 750 ? "14px" : `${rem}px`;
//     };
//     if (!doc.addEventListener) return;
//     win.addEventListener(resizeEvt, recalc, false);
//     doc.addEventListener('DOMContentLoaded', recalc, false);
//   }
// })(document, window);
