<template>
  <div class="vork-page">
    <vork-pc v-if="deviceMode === 'pc'" />
    <vork-m v-if="deviceMode === 'm'" />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import WorkDetailM from "./WorkDetailM";
import WorkDetail from "./WorkDetail";

export default {
  components: {
    "vork-pc": WorkDetail,
    "vork-m": WorkDetailM
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["deviceMode"]),
    ...mapState(["deviceWidth"])
  },
  methods: {}
};
</script>
<style lang="less" scoped></style>
