<template>
  <div class="page-basic-layout">
    <header class="page-basic-header">
      <BasicLayoutHeader />
    </header>
    <router-view />
    <footer class="page-basic-footer">
      <BasicLayoutFooter v-if="deviceMode === 'pc'" />
    </footer>
  </div>
</template>
<script type="text/javascript">
import BasicLayoutFooter from "./BasicLayoutFooter";
import BasicLayoutHeader from "./BasicLayoutHeader";
import { mapGetters } from "vuex";
export default {
  components: {
    BasicLayoutHeader,
    BasicLayoutFooter
  },
  computed: {
    ...mapGetters(["deviceMode"])
  }
};
</script>

<style type="text/css" lang="less" scoped>
.page-basic-layout {
  position: relative;
  min-height: 100vh;
}

.page-basic-header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;

  @media (max-width: 1200px) {
    width: 100%;
  }
}
.page-basic-footer {
  position: absolute;
  bottom: 20px;
  left: 30px;
  z-index: 1000;
}
</style>
