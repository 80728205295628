<template>
  <div class="page-basic-main">
    <div class="page-basic-main-left">
      <el-scrollbar class="scrollbar-section">
        <slot name="content-main"></slot>
      </el-scrollbar>
    </div>
    <div class="page-basic-main-right" ref="pageBasicMain">
      <el-scrollbar class="scrollbar-section">
        <slot name="content-side"></slot>
      </el-scrollbar>
    </div>
  </div>
</template>
<script type="text/javascript">
export default {
  data() {
    return {};
  }
};
</script>
<style type="text/css" lang="less" scoped>
.page-basic-main {
  position: relative;
  min-height: 100vh;
  @media (min-width: 1200px) {
    display: flex;
  }
}

.page-basic-main-left,
.page-basic-main-right {
  @media (min-width: 1200px) {
    flex: 1;
    width: 50%;
  }
}

.page-basic-main-left {
  padding-top: 68px;
  height: 100%;

  @media (min-width: 1200px) {
    padding-top: 86px;
    padding-left: 279px;
    padding-bottom: 108px;
    padding-right: 30px;
    height: 100vh;
    overflow: hidden;
  }

  @media (max-width: 750px) {
    padding-top: 60 / @ppr;
  }
}
.page-basic-main-right {
  @media (min-width: 1200px) {
    flex: none;
    height: 100vh;
    overflow: hidden;
  }
  @media (max-width: 1200px) {
    margin-top: 20px;
    padding-bottom: 108px;
  }
}
</style>
