var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"page-header"},[_c('div',{staticClass:"page-header-top"},[_c('router-link',{attrs:{"to":{ name: 'Works' }}},[_c('img',{staticClass:"page-header-logo",attrs:{"src":require("@/assets/images/douxi_logo.png")}})]),_c('span',{staticClass:"nav-toggler-btn",on:{"click":_vm.handleNavTogglerInMobile}},[_c('i',{class:_vm.navActive ? 'el-icon-s-unfold' : 'el-icon-s-fold'})])],1),_c('div',{class:['page-header-top-m', !_vm.navActive ? '' : 'active']},[_c('div',{class:[
        'page-header-top-m_top',
        _vm.$route.name == 'Works' ? 'works' : ''
      ]},[_c('div',{class:!_vm.navActive ? 'page-header-title' : 'page-header-title active'},[_vm._v(" "+_vm._s(_vm.headerTitle)+" ")]),_c('div',{class:_vm.navActive ? 'page-header-toggler' : 'page-header-toggler active',on:{"click":_vm.handleNavTogglerInMobile}},[_c('icon-svg',{attrs:{"svg-class":"page-icon-menu","svg-name":"menu"}})],1)]),(_vm.$route.name == 'Works')?_c('div',{class:['page-header-top-m_bottom']},_vm._l((_vm.workCats),function(cat,index){return _c('router-link',{key:cat.id,class:[
          'nav-sub-item',
          {
            active:
              _vm.$route.query.cat_id == cat.id ||
              (!_vm.$route.query.cat_id && index === 0)
          }
        ],attrs:{"to":{ name: 'Works', query: { cat_id: cat.id } }}},[_vm._v(" "+_vm._s(cat.name)+" ")])}),1):_vm._e()]),_c('div',{class:['page-header-nav', { active: _vm.navActive }]},[_c('router-link',{class:[
        'nav-item',
        {
          'router-link-active':
            _vm.$route.name === 'Works' || _vm.$route.name === 'WorkDetail'
        }
      ],attrs:{"to":{ name: 'Works', query: { cat_id: (_vm.workCats[0] || {}).id || 1 } }}},[_c('span',[_vm._v("作品展示/Works")]),_c('div',{staticClass:"nav-sub-list"},_vm._l((_vm.workCats),function(cat,index){return _c('router-link',{key:cat.id,class:[
            'nav-sub-item',
            {
              active:
                _vm.$route.query.cat_id == cat.id ||
                (!_vm.$route.query.cat_id && index === 0)
            }
          ],attrs:{"to":{ name: 'Works', query: { cat_id: cat.id } }}},[_vm._v(" "+_vm._s(cat.name)+" ")])}),1)]),_c('router-link',{staticClass:"nav-item",attrs:{"to":{ name: 'Service' }}},[_c('span',[_vm._v("甲方需知/Service")])]),_c('router-link',{staticClass:"nav-item",attrs:{"to":{ name: 'Awards' }}},[_c('span',[_vm._v("荣誉报道/Awards")])]),_c('router-link',{staticClass:"nav-item",attrs:{"to":{ name: 'Team' }}},[_c('span',[_vm._v("斗西成员/Team")])]),_c('router-link',{staticClass:"nav-item",attrs:{"to":{ name: 'Join' }}},[_c('span',[_vm._v("加入我们/Join")])]),_c('router-link',{staticClass:"nav-item",attrs:{"to":{ name: 'Contact' }}},[_c('span',[_vm._v("联系方式/Contact")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }