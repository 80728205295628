<template>
  <basic-layout v-loading="loading">
    <template v-slot:content-main>
      <div class="lists-section">
        <div
          class="list-section"
          v-for="(item, index) in groupDatas"
          :key="index"
        >
          <div class="list-title">{{ item.name }}</div>
          <div class="list-content">
            <animate-card
              v-for="(data, index) in item.children"
              :key="data.id"
              :name="data.name"
              :image="data.cover_url + '?x-oss-process=style/h200'"
              :slug="data.id"
              :index="index"
            />
          </div>
        </div>
      </div>
    </template>
    <template v-slot:content-side>
      <div class="side-section">
        <img
          src="https://oss-public.home-plan.cn/daylab/live.gif"
          class="live-image"
        />
      </div>
    </template>
  </basic-layout>
</template>

<script>
import BasicLayoutBody from "@/components/Layout/BasicLayoutBody";
import AnimateCard from "@/components/AnimateCard";
import workService from "@/globals/service/work";

export default {
  components: {
    "basic-layout": BasicLayoutBody,
    "animate-card": AnimateCard
  },
  data() {
    return {
      loading: true,
      sideData: {},
      teamMembers: []
    };
  },
  computed: {
    groupDatas() {
      return [
        {
          name: "斗西和他的小伙伴",
          value: 2,
          children: this.teamMembers.filter(data => data.type === 1)
        },
        {
          name: "斗西",
          value: 1,
          children: this.teamMembers.filter(data => data.type === 2)
        }
      ];
    }
  },
  created() {
    workService
      .teamMembers({ page_size: 100 })
      .then(res => {
        this.teamMembers = res;
        // this.sideData = res[0];
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    handleClickCard(item) {
      this.sideData = item;
    }
  }
};
</script>

<style type="text/css" lang="less" scoped>
.lists-section {
  padding-left: 20px;

  .list-section {
    .list-title {
      font-size: 14px;
      font-weight: 600;
      color: #333;
      margin-bottom: 4px;
    }

    .list-content {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 36px;
      .item {
        position: relative;
        width: 69px;
        height: 69px;
        margin: 0 20px 20px 0;
        .user-image {
          width: 69px;
          height: 69px;
        }
        .item_title {
          display: none;
          width: 69px;
          height: 69px;
          line-height: 69px;
          overflow: hidden;
          background: rgba(0, 0, 0, 0.5);
          color: #fff;
          text-align: center;
          font-size: 7px;
        }
        &:hover {
          transition: all 0.2s ease-out;
          opacity: 1;
          transform: scale(1.5);
          .item_title {
            z-index: 999;
            position: absolute;
            top: 0;
            left: 0;
            display: inline-block;
          }
        }
      }
    }
  }
}
.side-section {
  .live-image {
    max-width: 100%;
  }
}

@media (max-width: 750px) {
  .lists-section {
    padding: 20 / @ppr 25 / @ppr;
  }
  .side-section {
    padding: 0 25 / @ppr;
  }
}
</style>
