<template>
  <div
    class="card-item"
    :style="{ animationDelay: (index + 1) * 0.1 + `s` }"
    @click="handleClick"
  >
    <img class="card-item-img" :src="image" />
    <div class="card-item-mask"></div>
    <div class="card-item-name">{{ name }}</div>
  </div>
</template>
<script type="text/javascript">
export default {
  props: {
    index: {
      type: Number,
      default: 0
    },
    image: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    slug: {
      type: [String, Number],
      default: ""
    }
  },
  methods: {
    handleClick() {
      this.$emit("click", this.slug);
    }
  }
};
</script>
<style type="text/css" lang="less" scoped>
.card-item {
  transform: scale(0);
  animation: mymove 0.4s ease-in 1;
  animation-fill-mode: forwards;
  position: relative;
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 4px;
  width: 70px;
  height: 70px;
  cursor: pointer;
  &:hover {
    transform: scale(1.5);
    transition: all 0.3s;
    z-index: 2;
    .card-item-mask {
      opacity: 0.6;
    }
    .card-item-name {
      opacity: 1;
    }
  }
  .card-item-img {
    display: block;
    width: 100%;
    height: 100%;
  }
  .card-item-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0;
    transition: all 0.3s;
  }
  .card-item-name {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    transform: translateY(-50%);
    text-align: center;
    opacity: 0;
    transition: all 0.3s;
  }
}

@keyframes mymove {
  from {
    transform: scaleX(0.1) scaleY(0.1);
    transform-origin: 0% 0%;
  }
  to {
    transform: scaleX(1) scaleY(1);
    transform-origin: 0% 0%;
  }
}
</style>
