<template>
  <basic-layout v-loading="loading">
    <template v-slot:content-main>
      <div class="home-page">
        <div class="type-nav-container">
          <div
            :class="['type-nav-item', { active: item.value === filterType }]"
            @click="handleCheckoutWorkFilterType(item.value)"
            v-for="item in navDatas"
            :key="item.value"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="work-container">
          <div
            class="work-groups"
            v-for="item in groupsDatas"
            :key="item.value"
          >
            <div class="work-group-title">
              {{ item.name }}
              {{ filterType === "time" ? "" : " / " + item.value }}
            </div>
            <div class="work-list">
              <animate-card
                v-for="(work, index) in item.children"
                :key="work.slug"
                :name="work.name"
                :image="work.cover_url + '?x-oss-process=style/h200'"
                :slug="work.slug"
                :index="index"
                @click="handleCheckoutWork(work.id)"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:content-side>
      <div class="recommand-list">
        <div
          class="recommand-item"
          v-for="item in recommends"
          :key="item.id"
          @click="handleCheckoutWork(item.id)"
        >
          <div class="recommand-image-container">
            <img
              class="recommand-image"
              :src="item.cover_url + getWaterMarkQuery('daylab_index_wh1000')"
            />
          </div>
          <div class="recommand-mask">
            <img
              class="recommand-mask-search-icon"
              src="~@/assets/icon/seek.png"
            />
            <div class="recommand-mask-text">
              <div class="recommand-mask-name">{{ item.name }}</div>
              <div class="recommand-mask-category">{{ item.name_en }}</div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </basic-layout>
</template>

<script>
import BasicLayoutBody from "@/components/Layout/BasicLayoutBody";
import AnimateCard from "@/components/AnimateCard";
import workService from "@/globals/service/work";
import { getWaterMarkQuery } from "src/const/watermark";
export default {
  components: {
    "basic-layout": BasicLayoutBody,
    "animate-card": AnimateCard
  },
  data() {
    return {
      loading: true,
      filterType: "format",
      workCat: {},
      works: [],
      locations: [],
      recommends: []
    };
  },
  watch: {
    ["$route"](val, oldVal) {
      if (val.query.cat_id !== oldVal.query.cat_id) {
        this.getData();
      }
    }
  },
  computed: {
    cats() {
      let obj = {};
      this.works.forEach(work => {
        obj[work.cat_id] = {
          name: work.cat_name,
          value: work.cat_name_slug
        };
      });
      return Object.keys(obj).map(key => {
        return obj[key];
      });
    },
    navDatas() {
      const dates = this.works.map(data => String(data.date));
      const uniqueDates = Array.from(new Set(dates)).sort((a, b) => b - a);
      return [
        {
          name: this.workCat.display_name || "业态/Format",
          value: "format",
          children: this.cats
        },
        {
          name: "位置/Location",
          value: "location",
          children: this.locations.map(data => {
            return {
              name: data.name,
              value: data.name_en
            };
          })
        },
        {
          name: "时间/Time",
          value: "time",
          children: uniqueDates.map(data => {
            return {
              name: data,
              value: data
            };
          })
        }
      ];
    },
    groupsDatas() {
      const groups =
        this.navDatas.find(data => data.value === this.filterType)?.children ||
        [];

      const filterKey = {
        format: "cat_name_slug",
        location: "location_name_slug",
        time: "date"
      };

      const groupsDatas = groups.map(data => {
        data.children = this.works.filter(item => {
          return item[filterKey[this.filterType]] === data.value;
        });
        return data;
      });

      return groupsDatas.filter(data => data.children.length);
    }
  },
  created() {
    this.getData();
  },
  methods: {
    getWaterMarkQuery,
    getData() {
      let catId = this.$route.query.cat_id || 1;
      let promiseArr = [
        workService.works({
          cat_id: catId,
          page_size: 100
        }),
        workService.workLocations(),
        workService.workRecommend({
          cat_id: catId
        })
      ];
      if (catId) {
        promiseArr.push(workService.workCat(catId));
      }
      Promise.all(promiseArr)
        .then(([worksRes, locations, recommends, cat]) => {
          this.works = worksRes.list;
          this.locations = locations;
          this.recommends = recommends;
          if (cat) this.workCat = cat;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleCheckoutWorkFilterType(value) {
      this.filterType = value;
    },
    handleClickCard(e) {
      this.handleCheckoutWork(e);
    },
    handleCheckoutWork(slug) {
      this.$router.push({
        name: "WorkDetail",
        params: {
          slug: slug
        },
        query: {
          ...this.$route.query,
          type: this.filterType
        }
      });
    }
  }
};
</script>

<style type="text/css" lang="less" scoped>
.home-page {
  padding-left: 19px;
  .type-nav-item {
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    color: #717171;
    //border-bottom: 2px solid #fff;
    margin-right: 57px;
    z-index: 1;
    cursor: pointer;
    &.active {
      position: relative;
      color: #000;
    }
    &.active::before {
      position: absolute;
      content: "";
      left: -2px;
      width: 16px;
      height: 16px;
      display: inline-block;
      border-radius: 50%;
      background: #7ff226;
      mix-blend-mode: multiply;
    }
  }
  .work-container {
    margin-top: 42px;
    .work-groups {
      margin-bottom: 18px;

      .work-group-title {
        margin-bottom: 4px;
        font-size: 14px;
        font-weight: 600;
      }
      .work-list {
        font-size: 0;
      }
    }
  }
}

.recommand-list {
  font-size: 0;
  .recommand-item {
    position: relative;
    transition: all 0.3s;
    .recommand-mask {
      display: none;
      .recommand-mask-search-icon {
        display: none;
      }
      .recommand-mask-name {
        display: none;
      }
    }
    &:hover {
      .recommand-mask {
        cursor: pointer;
        animation: desalt 0.2s ease-in 1;
        display: inline-block;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.3);
        .recommand-mask-search-icon {
          display: inline-block;
          width: 16px;
          height: 16px;
        }
        .recommand-mask-text {
          display: inline-block;
          font-size: 14px;
          font-weight: 600;
          color: #fff;
        }
      }
    }
  }
}
@keyframes desalt {
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.3);
  }
}
</style>
