<template>
  <div class="rich-content">
    <slot></slot>
  </div>
</template>
<script type="text/javascript">
export default {
  data() {
    return {};
  }
};
</script>
<style type="text/css" lang="less" scoped>
.rich-content {
  padding: 0 12px;
  color: #322825;

  p,
  h2 {
    margin-top: 0;
    overflow: hidden;
  }
}

@media (max-width: 750px) {
  .rich-content {
    padding: 0;
  }
}
</style>
