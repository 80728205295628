<template>
  <header class="page-header">
    <!-- PC版本的头 -->
    <div class="page-header-top">
      <router-link :to="{ name: 'Works' }"
        ><img class="page-header-logo" src="@/assets/images/douxi_logo.png" />
      </router-link>
      <span class="nav-toggler-btn" @click="handleNavTogglerInMobile">
        <i :class="navActive ? 'el-icon-s-unfold' : 'el-icon-s-fold'"></i>
      </span>
    </div>
    <!-- 移动版的头 -->
    <div :class="['page-header-top-m', !navActive ? '' : 'active']">
      <div
        :class="[
          'page-header-top-m_top',
          $route.name == 'Works' ? 'works' : ''
        ]"
      >
        <div
          :class="!navActive ? 'page-header-title' : 'page-header-title active'"
        >
          {{ headerTitle }}
        </div>
        <div
          :class="
            navActive ? 'page-header-toggler' : 'page-header-toggler active'
          "
          @click="handleNavTogglerInMobile"
        >
          <icon-svg svg-class="page-icon-menu" svg-name="menu" />
        </div>
      </div>
      <div :class="['page-header-top-m_bottom']" v-if="$route.name == 'Works'">
        <router-link
          :class="[
            'nav-sub-item',
            {
              active:
                $route.query.cat_id == cat.id ||
                (!$route.query.cat_id && index === 0)
            }
          ]"
          v-for="(cat, index) of workCats"
          :key="cat.id"
          :to="{ name: 'Works', query: { cat_id: cat.id } }"
        >
          {{ cat.name }}
        </router-link>
      </div>
    </div>
    <div :class="['page-header-nav', { active: navActive }]">
      <router-link
        :to="{ name: 'Works', query: { cat_id: (workCats[0] || {}).id || 1 } }"
        :class="[
          'nav-item',
          {
            'router-link-active':
              $route.name === 'Works' || $route.name === 'WorkDetail'
          }
        ]"
        ><span>作品展示/Works</span>
        <div class="nav-sub-list">
          <router-link
            :class="[
              'nav-sub-item',
              {
                active:
                  $route.query.cat_id == cat.id ||
                  (!$route.query.cat_id && index === 0)
              }
            ]"
            v-for="(cat, index) of workCats"
            :key="cat.id"
            :to="{ name: 'Works', query: { cat_id: cat.id } }"
          >
            {{ cat.name }}
          </router-link>
        </div>
      </router-link>

      <router-link :to="{ name: 'Service' }" class="nav-item"
        ><span>甲方需知/Service</span></router-link
      >
      <router-link :to="{ name: 'Awards' }" class="nav-item"
        ><span>荣誉报道/Awards</span></router-link
      >
      <router-link :to="{ name: 'Team' }" class="nav-item"
        ><span>斗西成员/Team</span></router-link
      >
      <!-- <router-link :to="{ name: 'Blog' }" class="nav-item"
        >斗西记/Blog</router-link
      > -->
      <router-link :to="{ name: 'Join' }" class="nav-item"
        ><span>加入我们/Join</span></router-link
      >
      <router-link :to="{ name: 'Contact' }" class="nav-item"
        ><span>联系方式/Contact</span></router-link
      >
    </div>
  </header>
</template>
<script type="text/javascript">
import { mapState } from "vuex";
import workService from "@/globals/service/work";
export default {
  data() {
    return {
      navActive: false,
      routerTitle: "",
      workCats: []
    };
  },
  watch: {
    $route: "handledisableNavTogglerInMobile"
  },
  computed: {
    ...mapState(["workTitle"]),
    headerTitle() {
      return this.workTitle || this.routerTitle;
    }
  },
  created() {
    this.setRouterName();
    this.getWorkCat();
  },
  methods: {
    getWorkCat() {
      workService.workCats().then(cats => {
        this.workCats = cats;
      });
    },
    handledisableNavTogglerInMobile() {
      this.navActive = false;
      this.setRouterName();
    },
    handleNavTogglerInMobile() {
      this.navActive = !this.navActive;
    },
    setRouterName() {
      this.routerTitle = this.$route.meta.title;
    }
  }
};
</script>

<style type="text/css" lang="less" scoped>
@activeColor: #58ff1e;
@media (min-width: 750px) {
  .page-header-top-m {
    display: none;
  }
}

@media (max-width: 750px) {
  .page-header {
    background: #fff;
    min-width: 350px;
    .page-header-top {
      display: none;
    }
    .nav-sub-list {
      display: none !important;
    }
    .page-header-top-m {
      position: fixed;
      display: flex;
      width: 100%;
      flex-direction: column;
      background: #fff;
      &.active {
        .page-header-top-m_bottom {
          opacity: 0;
        }
      }
      .page-header-top-m_top {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 21 / @ppr 25 / @ppr 15 / @ppr;
        &.works {
          padding-bottom: 0;
        }
      }
      .page-header-top-m_bottom {
        display: flex;
        align-items: center;
        padding: 10 / @ppr 25 / @ppr;
        opacity: 1;
        transition: all 0.2s ease;
        .nav-sub-item {
          position: relative;
          line-height: 20 / @ppr;
          font-weight: 600;
          font-size: 14 / @ppr;
          color: #aeaeae;
          &.active {
            color: #000000;
            &::before {
              position: absolute;
              left: -7 / @ppr;
              top: 1 / @ppr;
              width: 16 / @ppr;
              height: 16 / @ppr;
              content: "";
              background: #00ff24;
              mix-blend-mode: multiply;
              border-radius: 50%;
            }
          }
          & + .nav-sub-item {
            margin-left: 44 / @ppr;
            &::after {
              position: absolute;
              left: -26 / @ppr;
              top: 50%;
              width: 1 / @ppr;
              height: 22 / @ppr;
              content: "";
              background: #eaeaea;
              transform: translate(0, -50%);
            }
          }
        }
      }
      .page-header-title {
        position: relative;
        font-weight: 600;
        font-size: 28 / @ppr;
        // line-height: 30 / @ppr;
        opacity: 1;
        transition: all 0.2s ease;
        &.active {
          opacity: 0;
        }
        &:before {
          position: absolute;
          left: -9 / @ppr;
          top: -2 / @ppr;
          width: 25 / @ppr;
          height: 25 / @ppr;
          content: "";
          background: #00ff24;
          mix-blend-mode: multiply;
          border-radius: 50%;
          z-index: -1;
        }
      }
      .page-header-toggler {
        position: relative;
        z-index: 9;
        &.active {
          .page-icon-menu {
            transform: rotate(0deg);
          }
        }
        .page-icon-menu {
          width: 36 / @ppr;
          height: 36 / @ppr;
          transition: all 0.2s;
          transform: rotate(-45deg);
        }
      }
    }

    .page-header-nav {
      height: 0;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      padding-left: 28 / @ppr;
      &.active {
        position: fixed;
        top: 59 / @ppr;
        width: 100%;
        height: calc(100vh - 1.6rem);
        background: #fff;
      }

      .nav-item {
        color: #000;
        position: relative;
        font-size: 26 / @ppr;
        font-weight: 600;
        line-height: 36 / @ppr;
        margin-bottom: 30 / @ppr;
        cursor: pointer;

        &.router-link-active {
          span {
            position: relative;
            z-index: 1;
            &:after {
              content: "";
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              width: 100%;
              height: 16 / @ppr;
              background: #58ff1e;
              z-index: -1;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1200px) and (min-width: 750px) {
  .page-header {
    padding-top: 6px;
    background: #fff;
    .page-header-top {
      position: fixed;
      top: 0px;
      width: 100%;
      height: 58px;
      padding-left: 12px;
      display: flex;
      justify-content: space-between;
      background: white;
      .page-header-logo {
        height: 50px;
        cursor: pointer;
      }
      .nav-toggler-btn {
        position: relative;
        left: -12px;
        top: 12px;
        display: inline-block;
        width: 28px;
        height: 28px;
        transform: rotate(-90deg);
        font-size: 28px;
        cursor: pointer;
      }
    }

    .page-header-nav {
      height: 0;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      padding-left: 12px;
      &.active {
        position: fixed;
        top: 58px;
        width: 100%;
        height: 210px;
        background: white;
      }

      .nav-item {
        padding: 3px 12px;
        color: #666;
        position: relative;
        line-height: 2;
        font-size: 12px;
        font-weight: 700;
        cursor: pointer;
        &.router-link-active {
          color: #000;
          font-weight: 800;
          transition: all 0.5s ease;
          &:before {
            content: "";
            position: absolute;
            top: 12px;
            left: 0;
            transform: translateY(-10%);
            border-left: 6px solid #000;
            border-right: none;
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
          }
        }
      }
    }
  }
}
@media (min-width: 1200px) {
  .page-header {
    height: 100vh;
    width: 279px;
    padding-top: 6px;
    padding-left: 14px;
    padding-bottom: 108px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .page-header-top {
      padding-bottom: 20px;
      .page-header-logo {
        height: 56px;
        cursor: pointer;
      }
      .nav-toggler-btn {
        display: none;
      }
    }
    .page-header-nav {
      position: relative;
      flex: 1;
      display: flex;
      flex-direction: column;
      border-right: 4px solid #7ff226;
      padding-right: 20px;
      .nav-item {
        display: inline-block;
        padding: 0 0 12.5px 0;
        flex: none;
        height: 50px;
        min-height: 50px;
        line-height: 33px;
        font-size: 22px;
        font-weight: 700;
        color: #000000;
        cursor: pointer;
        transition: flex 0.5s ease;
        &.router-link-active {
          flex: 1;
          color: #000;
          font-weight: 800;
          span {
            display: inline-block;
            line-height: 26px;
            mix-blend-mode: multiply;
            background: linear-gradient(
              0deg,
              transparent 20%,
              #7ff226 20%,
              #7ff226 80%,
              transparent 80%
            );
          }
        }
      }
    }
  }
}
.nav-item {
  &.router-link-active {
    .nav-sub-list {
      display: block;
    }
  }
}
.nav-sub-list {
  display: none;
  margin-left: 7px;
  margin-top: 16px;
  .nav-sub-item {
    position: relative;
    display: block;
    line-height: 22px;
    font-size: 16px;
    font-weight: 600;
    color: #c4c4c4;
    z-index: 1;
    cursor: pointer;
    & + .nav-sub-item {
      margin-top: 10px;
    }
    &::before {
      position: absolute;
      content: "";
      left: -7px;
      width: 14px;
      height: 14px;
      display: inline-block;
      border-radius: 50%;
      background: #c4c4c4;
      mix-blend-mode: multiply;
    }
    &.active {
      color: #000;
      &::before {
        background: #7ff226;
      }
    }
  }
}
</style>
