const workData = [
  {
    slug: "zha-club",
    name_zh: "渣Club",
    name_en: "Zha Club",
    category_zh: "酒吧设计",
    category_en: "Store design",
    location_zh: "义乌",
    location_en: "Yiwu",
    location: "yiwu",
    format: "fb",
    typology: "commercial",
    time: "2015",
    area_zh: "50 sqm",
    area_en: "50 sqm",
    status_zh: "设计完成、施工完成",
    status_en: "Design Completed and Constructed",
    author: "斗西、大头、Aimee",
    img_url:
      "http://www.daylab.cn/wp-content/themes/douxi/thumb.php?src=http://www.daylab.cn/wp-content/uploads/2020/06/20200605463.jpg&h=100&w=100&zc=0",
    content: `
      <img src="http://www.daylab.cn/wp-content/uploads/2020/06/20200605405.jpg" alt="" /> 
      <img src="http://www.daylab.cn/wp-content/uploads/2020/06/20200605553.jpg" alt="" /> 
      <img src="http://www.daylab.cn/wp-content/uploads/2020/06/20200605463.jpg" alt="" /> 
      <img src="http://www.daylab.cn/wp-content/uploads/2020/06/20200605284.jpg" alt="" />
      <img src="http://www.daylab.cn/wp-content/uploads/2020/06/20200605697.jpg" alt="" /> 
      <img src="http://www.daylab.cn/wp-content/uploads/2020/06/20200605641.jpg" alt="" /> 
      <img src="http://www.daylab.cn/wp-content/uploads/2020/06/20200605582.jpg" alt="" /> 
      <img src="http://www.daylab.cn/wp-content/uploads/2020/06/20200605897.jpg" alt="" />
    `
  },
  {
    slug: "itafe-up-store-2",
    name_zh: "ITAFE",
    name_en: "ITAFE",
    category_zh: "商业空间设计",
    category_en: "Store design",
    location_zh: "义乌",
    location_en: "Yiwu",
    location: "yiwu",
    format: "fb",
    typology: "commercial",
    time: "2019",
    area_zh: "30 sqm",
    area_en: "30 sqm",
    status_zh: "设计完成、施工完成",
    status_en: "Design Completed and Constructed",
    author: "斗西、大头、Aimee",
    img_url:
      "http://www.daylab.cn/wp-content/themes/douxi/thumb.php?src=http://www.daylab.cn/wp-content/uploads/2020/06/20200605565.jpg&h=100&w=100&zc=1",
    content: `
      <img src="http://img.daylab.cn/wp-content/uploads/2020/06/20200605565.jpg" alt="" />
      <img src="http://img.daylab.cn/wp-content/uploads/2020/06/20200605453.jpg" alt="" />
      <img src="http://img.daylab.cn/wp-content/uploads/2020/06/20200605339.jpg" alt="" /> 
      <img src="http://img.daylab.cn/wp-content/uploads/2020/06/20200605652.jpg" alt="" /> 
      <img src="http://img.daylab.cn/wp-content/uploads/2020/06/2020060523.jpg" alt="" /> 
      <img src="http://img.daylab.cn/wp-content/uploads/2020/06/20200605224.jpg" alt="" /> 
      <img src="http://img.daylab.cn/wp-content/uploads/2020/06/2020060569.jpg" alt="" />
    `
  },
  {
    slug: "fancy-fruit-2",
    name_zh: "FancyFruit",
    name_en: "FancyFruit",
    category_zh: "商业空间设计",
    category_en: "Store design",
    location_zh: "上海",
    location_en: "Shanghai",
    location: "shanghai",
    format: "fb",
    typology: "commercial",
    time: "2015",
    area_zh: "50 sqm",
    area_en: "50 sqm",
    status_zh: "设计完成、施工完成",
    status_en: "Design Completed and Constructed",
    author: "斗西、大头、Aimee",
    img_url:
      "http://www.daylab.cn/wp-content/themes/douxi/thumb.php?src=http://www.daylab.cn/wp-content/uploads/2019/01/20190108549.jpg&h=100&w=100&zc=1",
    content: `
      <img src="http://img.daylab.cn/wp-content/uploads/2019/01/20190108928.jpg" alt="">
    `
  },
  {
    slug: "itafe-up-store",
    name_zh: "ITAFE",
    name_en: "ITAFE",
    category_zh: "商业空间设计",
    category_en: "Store design",
    location_zh: "义乌",
    location_en: "Yiwu",
    location: "yiwu",
    format: "fb",
    typology: "commercial",
    time: "2015",
    area_zh: "50 sqm",
    area_en: "50 sqm",
    status_zh: "设计完成、施工完成",
    status_en: "Design Completed and Constructed",
    author: "斗西、大头、Aimee",
    img_url:
      "http://www.daylab.cn/wp-content/themes/douxi/thumb.php?src=http://www.daylab.cn/wp-content/uploads/2019/01/20190108928.jpg&h=100&w=100&zc=1",
    content: `
      <img src="http://img.daylab.cn/wp-content/uploads/2020/06/20200605908.jpg" alt="" >
      <img src="http://img.daylab.cn/wp-content/uploads/2020/06/20200605961.jpg" alt=""> 
      <img src="http://img.daylab.cn/wp-content/uploads/2020/06/2020060575.jpg" alt="" >
      <img src="http://img.daylab.cn/wp-content/uploads/2020/06/20200605138.jpg" alt="" > 
      <img src="http://img.daylab.cn/wp-content/uploads/2020/06/2020060531.jpg" alt="" > 
      <img src="http://img.daylab.cn/wp-content/uploads/2020/06/20200605918.jpg" alt="" > 
      <img src="http://img.daylab.cn/wp-content/uploads/2020/06/20200605337.jpg" alt="" > 
      <img src="http://img.daylab.cn/wp-content/uploads/2020/06/20200605117.jpg" alt="" > 
      <img src="http://img.daylab.cn/wp-content/uploads/2020/06/20200605596.jpg" alt="" > 
      <img src="http://img.daylab.cn/wp-content/uploads/2020/06/20200605181.jpg" alt="" > 
      <img src="http://img.daylab.cn/wp-content/uploads/2020/06/20200605324.jpg" alt="" > 
      <img src="http://img.daylab.cn/wp-content/uploads/2020/06/20200605185.jpg" alt="" > 
      <img src="http://img.daylab.cn/wp-content/uploads/2020/06/20200605747.jpg" alt="" > 
      <img src="http://img.daylab.cn/wp-content/uploads/2020/06/20200605621.jpg" alt="" >
      <img src="http://img.daylab.cn/wp-content/uploads/2020/06/20200605820.jpg" alt="" > 
      <img src="http://img.daylab.cn/wp-content/uploads/2020/06/20200605891.jpg" alt="" >
      <img src="http://img.daylab.cn/wp-content/uploads/2020/06/20200605509.jpg" alt="" >
      <img src="http://img.daylab.cn/wp-content/uploads/2020/06/20200605929.jpg" alt="" >
    `
  }
];

export default workData;
