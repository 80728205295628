<template>
  <basic-layout>
    <template v-slot:content-main>
      <div class="join-page">
        <rich-text>
          <div>
            <h3 class="mt-0">
              斗西设计是一个成立于上海的牛逼而不装逼的团队，坦诚的说，这气质在业内弥足珍贵。
            </h3>
            <p>
              <b>斗西的设计理念</b><br />
              「追求不同」是过去五年里我们团队一直在做的事情。<br />
              我们好奇各个领域，不想停留在某个细分里重复自己，斗西推崇对「不同品牌」的「不同问题」，用「不同的设计手法」和「不同的风格」来应对与解决，而不是用某种固定的语言来诠释「设计师自己」，这可能也是我们理解的「商业项目」与「艺术创作」的区别。<br />
            </p>
            <p>
              <b>斗西的价值理念</b><br />
              团队三观狂正，对自我人格道德要求极高，诚然，“道德”和“设计能力”不存在正相关，毕竟无数艺术大师生活中简直是无耻混蛋。可这不妨碍我们向往高尚，比起奢望成为有争议的大师，我们更想以“职业设计师”的身份赢得尊重。<br />
              我们尊重每一项委任，并且持续配合跟进以致项目落地成功，我们要求设计师会深入一线，而不是画完图纸丢出去万事大吉。<br />
              斗西设计一个理想主义的团队！创立至今不曾收过一分设计费之外的费用，也没有给出过一分「优良设计」之外的”好处”。我们只想用更加专业优秀的天赋和强大的内心做在腐朽当下那个闪着理想之光的团队。
            </p>
            <p>
              <b>招聘职位</b><br />
              <b>O 资深室内设计师</b><br />
              1.精英型人格，5年以上空间设计经验。<br />
              2.具备独立且优质的完成从概念提案到落地完成全套图纸和设计工作的能力，并且能管理团队成员。<br />
              3.能驾驭各类设计风格，美感极佳。<br />
              4.精通制图规范、施工节点、装修材质、工艺及施工流程。<br />
              <b>O 优秀室内设计师</b><br />
              1. 名校毕业，精英型人格，3年以上空间设计经验。<br />
              2.
              具备独立且优质的完成从概念提案到落地完成全套图纸和设计工作的能力。<br />
              3. 能驾驭部分设计风格，美感极佳。<br />
              4. 精通制图规范、施工节点、装修材质、工艺及施工流程。<br />
              <b>O 美学家装设计师</b><br />
              1. 名校毕业，精英型人格，5年以上家装设计经验。<br />
              2.
              具备独立且优质的完成从概念提案到落地完成全套图纸和设计工作的能力。<br />
              3. 能驾驭部分设计风格，美感极佳。<br />
              4. 精通制图规范、施工节点、装修材质、工艺及施工流程。<br />
              5. 我们新开辟的板块，是时候做些明星家装了。<br />
              <b>O 设计培养生</b><br />
              1.优秀毕业生，<br />
              2.软件纯熟，聪明伶俐。<br />
              3.高于常人的道德与价值观。<br />
              团队里都是多年战友，人员稳定，因此很乐于培养萌新，如果你足够优秀，可以来在这里感受下一日千里。<br />
              <b>O 施工图设计师</b><br />
              1. 3年以上系统性施工图工作经验<br />
              2. 具备丰富的商店类项目管理经验<br />
              3. 精通平面系统图与道具图纸的绘制<br />
              4. 高效，自驱力强的行业精英<br />
              <b>O 优秀洋气的PR</b><br />
              1.协调媒体和参展活动。<br />
              2.运营我们的公众号和微博。<br />
              3.英文流利，需要有艺术细胞。<br />
              4.熟悉国内外奖项，设计专业海归最佳。
            </p>
            <p>另一些你可能感兴趣的事情：</p>
            <p>
              <b>关于氛围</b><br />
              这么说吧，我厂设计能力没资格乱吹，但氛围自夸起来还是理直气壮的，我经常担心大家笑得过于放纵会被邻居举报。
              如果你对乒乓球，踢毽子，或者篮球有两把刷子，嗯，这里有好多把刷子等着你：）。<br />
            </p>
            <p>
              <b>关于压力</b><br />
              团队整体的宽松氛围，很难让压力弥漫，但由于工作标准比较高，「不甘落后的危机感」，和「达成顶尖成就的自驱力」，仍然时刻在许多优秀同事心中。<br />
              关于加班：<br />
              加班吗？遗憾的说，尽管我作为管理者做出过许多努力，但受迫于行业属性，加班在所难免。但我厂仍然秉承两个原则：1，不周末加班，保证大家心情的彻底放松。
              2，不通宵加班，不透支大家健康。<br />
            </p>
            <p>
              <b>关于福利</b><br />
              阿姨午餐，下午茶，零食，和乐于分享家乡特产的各种同事，
              每年出国的大型游学，年底的双周长假，在我们仍然是个小厂的时候，也尽可能的建设福利，因此不敢想象我厂发达后，会是一番什么样的慷慨气象。<br />
            </p>
            <p>
              <b>关于恶趣味</b><br />
              公司社交文化推崇「不给人负担」，没有任何日常应酬，没有任何酒场压力，甚至年会时，连领导开场白环节都无，除了抽奖就是吃喝玩乐。<br />
              原本想写更多，但我是一个羞于自卖自夸的人，也总觉得做的还不够，索性就说到这里吧，希望有惊喜可以等你。<br />
            </p>
            <p style="text-align: right;">—— 斗西</p>
            <p>
              <b>应聘方式: </b><br />
              （1）《个人简历》<br />
              （2）《作品集》（只阅PDF文件）<br />
              发送至：hr@daylab.cn<br />
              联系人：杨小姐
            </p>
            <p>
              <b>注：</b><br />
              （1）作品集（只阅PDF文件），反应真实能力的设计案例，施工图纸等，如是团队合作项目，请写明负责参与的部分及工作内容。<br />
              （2）邮件标题请具体写明“应聘职位”及“基本工资要求”。
            </p>
            <p>地址：上海市黄浦区斜土东路176号昂云空间501</p>
          </div>
        </rich-text>
      </div>
    </template>
    <template v-slot:content-side>
      <div class="img-content">
        <img
          src="https://oss-public.home-plan.cn/daylab/join-1.jpg?x-oss-process=style/w1280"
        />
        <img
          src="https://oss-public.home-plan.cn/daylab/join-2.jpg?x-oss-process=style/w1280"
        />
      </div>
    </template>
  </basic-layout>
</template>

<script>
import BasicLayoutBody from "@/components/Layout/BasicLayoutBody";
import RichText from "@/components/RichText";

export default {
  components: {
    "basic-layout": BasicLayoutBody,
    "rich-text": RichText
  },
  data() {
    return {
      loading: false
    };
  },
  created() {},
  methods: {}
};
</script>

<style type="text/css" lang="less" scoped>
@media (max-width: 750px) {
  .join-page {
    padding: 20 / @ppr 25 / @ppr;
  }
  .img-content {
    padding: 0 25 / @ppr;
  }
}
</style>
