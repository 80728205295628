<template>
  <basic-layout v-loading="loading">
    <template v-slot:content-main>
      <div class="home-page">
        <div class="type-nav-container">
          <div
            :class="['type-nav-item', { active: item.value === filterType }]"
            @click="handleCheckoutWorkFilterType(item.value)"
            v-for="item in navDatas"
            :key="item.value"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="work-container">
          <div
            class="work-groups"
            v-for="item in groupsDatas"
            :key="item.value"
          >
            <div class="work-group-title">
              {{ item.name }}
              {{ filterType === "time" ? "" : " / " + item.value }}
            </div>
            <div class="work-list">
              <animate-card
                v-for="(work, index) in item.children"
                :key="work.slug"
                :name="work.name"
                :image="work.cover_url + '?x-oss-process=style/h200'"
                :slug="work.id"
                :index="index"
                @click="handleClickCard"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:content-side>
      <div
        class="work-detail-container"
        ref="workDetail"
        v-loading="detailLoading"
      >
        <div class="work-detail">
          <div class="zh-section section">
            <div class="work-item"><strong>品牌：</strong>{{ work.name }}</div>
            <div class="work-item">
              <strong>业态：</strong>{{ work.category }}
            </div>
            <div class="work-item" v-if="false">
              <strong>地点：</strong>{{ work.location_id }}
            </div>
            <div class="work-item">
              <strong>面积：</strong>{{ work.area }} sqm.
            </div>
            <div class="work-item">
              <strong>状态：</strong
              >{{
                PROGRESS[work.progress_id] ? PROGRESS[work.progress_id].zh : ""
              }}
            </div>
            <div class="work-item" v-if="work.city">
              <strong>城市：</strong>{{ work.city }}
            </div>
            <div class="work-item">
              <strong>时间：</strong>{{ formatDate(work.date) }}
            </div>
            <div class="work-item">
              <strong>主创：</strong>{{ work.creator }}
            </div>
          </div>
          <div class="en-section section">
            <div class="work-item">
              <strong>Client：</strong>{{ work.name_en }}
            </div>
            <div class="work-item">
              <strong>Format：</strong>{{ work.category_en }}
            </div>
            <div class="work-item" v-if="false">
              <strong>Location：</strong>{{ work.location_id }}
            </div>
            <div class="work-item">
              <strong>Area：</strong>{{ work.area }} sqm.
            </div>
            <div class="work-item">
              <strong>Status：</strong
              >{{
                PROGRESS[work.progress_id] ? PROGRESS[work.progress_id].en : ""
              }}
            </div>
            <div class="work-item" v-if="work.city_en">
              <strong>City：</strong>{{ work.city_en }}
            </div>
            <div class="work-item">
              <strong>Timeframe：</strong>{{ formatDate(work.date) }}
            </div>
          </div>
        </div>
        <div class="work-content" v-html="work.content"></div>
      </div>
    </template>
  </basic-layout>
</template>

<script>
import BasicLayoutBody from "@/components/Layout/BasicLayoutBody";
import workNavData from "@/mocks/workNavData";
import workData from "@/mocks/workData";
import AnimateCard from "@/components/AnimateCard";
import workService from "@/globals/service/work";
import dayjs from "dayjs";
import { PROGRESS } from "@/utils/const";

export default {
  components: {
    "basic-layout": BasicLayoutBody,
    "animate-card": AnimateCard
  },
  data() {
    return {
      PROGRESS,
      loading: true,
      detailLoading: false,
      navData: workNavData,
      workData: workData,
      filterType: "format",
      slug: null,
      workCat: {},
      work: {},
      works: [],
      formats: [],
      locations: []
    };
  },
  computed: {
    cats() {
      let obj = {};
      this.works.forEach(work => {
        obj[work.cat_id] = {
          name: work.cat_name,
          value: work.cat_name_slug
        };
      });
      return Object.keys(obj).map(key => {
        return obj[key];
      });
    },
    navDatas() {
      const dates = this.works.map(data => String(data.date));
      const uniqueDates = Array.from(new Set(dates)).sort((a, b) => b - a);

      return [
        {
          name: this.workCat.display_name || "业态/Format",
          value: "format",
          children: this.cats
        },
        {
          name: "位置/Location",
          value: "location",
          children: this.locations.map(data => {
            return {
              name: data.name,
              value: data.name_en
            };
          })
        },
        {
          name: "时间/Time",
          value: "time",
          children: uniqueDates.map(data => {
            return {
              name: data,
              value: data
            };
          })
        }
      ];
    },
    groupsDatas() {
      const groups =
        this.navDatas.find(data => data.value === this.filterType)?.children ||
        [];

      const filterKey = {
        format: "cat_name_slug",
        location: "location_name_slug",
        time: "date"
      };

      const groupsDatas = groups.map(data => {
        data.children = this.works.filter(item => {
          return item[filterKey[this.filterType]] === data.value;
        });
        return data;
      });

      return groupsDatas.filter(data => data.children.length);
    }
  },
  watch: {
    $route: function() {
      this.getWorkDetail();
    }
  },
  created() {
    this.getData();
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format("YYYY");
    },
    getData() {
      let catId = this.$route.query.cat_id || 1;
      this.slug = this.$route.params.slug;
      let promiseArr = [
        workService.works({
          cat_id: catId,
          page_size: 100
        }),
        workService.workFormats(),
        workService.workLocations(),
        workService.workDetail(this.slug)
      ];
      if (catId) {
        promiseArr.push(workService.workCat(catId));
      }
      Promise.all(promiseArr)
        .then(([worksRes, formats, locations, work, cat]) => {
          this.works = worksRes.list;
          this.formats = formats;
          this.locations = locations;
          this.work = work;
          if (cat) this.workCat = cat;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getWorkDetail() {
      this.slug = this.$route.params.slug;
      this.detailLoading = true;
      workService
        .workDetail(this.slug)
        .then(res => {
          this.work = res;
          this.$refs.workDetail.scrollIntoView();
        })
        .finally(() => {
          this.detailLoading = false;
        });
    },
    handleCheckoutWorkFilterType(value) {
      this.filterType = value;
    },
    handleClickCard(slug) {
      this.handleCheckoutWork(slug);
    },
    handleCheckoutWork(slug) {
      if (slug !== this.slug) {
        this.$router.push({
          name: "WorkDetail",
          params: {
            slug: slug
          },
          query: {
            ...this.$route.query,
            type: this.filterType
          }
        });
      }
    }
  }
};
</script>

<style type="text/css" lang="less" scoped>
.home-page {
  padding-left: 19px;
  .type-nav-item {
    display: inline-block;
    line-height: 22px;
    font-size: 16px;
    font-weight: 600;
    color: #717171;
    margin-right: 57px;
    cursor: pointer;
    &.active {
      position: relative;
      color: #000;
    }
    &.active::before {
      position: absolute;
      content: "";
      left: -2px;
      width: 16px;
      height: 16px;
      display: inline-block;
      border-radius: 50%;
      background: #7ff226;
      mix-blend-mode: multiply;
    }
  }
  .work-container {
    margin-top: 42px;
    .work-groups {
      margin-bottom: 18px;
      .work-group-title {
        margin-bottom: 4px;
        font-size: 14px;
        font-weight: 600;
      }
      .work-list {
        font-size: 0;
      }
    }
  }
}
.work-detail {
  margin-bottom: 10px;
  padding-top: 45px;
  display: flex;
  justify-content: space-between;

  .section {
    width: 50%;
  }

  .work-item {
    font-size: 12px;
    color: #322825;
    line-height: 1.6;
    strong {
      font-weight: 700;
    }
  }
}
</style>
