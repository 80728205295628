<template>
  <basic-layout>
    <template v-slot:content-main>
      <div class="service-page">
        <rich-text>
          <h3 class="mt-0">
            <strong
              >“斗西设计是一支牛逼却不装逼的设计团队——坦诚的说，这气质在业内弥足珍贵”</strong
            >
          </h3>
          <p>
            你很难想象，一支团队几乎能在品牌的平面视觉领域、商业、办公空间领域、家居产品领域、施工制作管理领域、每个领域都能胜任，且每一项都处于业内领先的位置。<br />LINE
            Friends
            ，FrenzyFountain，LILY，网鱼网咖，魔方公寓，小象馆，一茶一坐，安可济，FancyFruit，爱哆哆，茶风暴。Voxflor（华腾），大卫杜夫，LKK，我们服务的对象跨领域，跨业态，我们最希望的就是帮助一些新业态焕发能量。
          </p>
          <p>&nbsp;</p>
          <p><strong>服务原则：</strong></p>
          <div class="dcore-body">
            <p>
              出于对您的尊重，这里并非所有任务都承接。设计工作不是富士康的生产线，给梵高100个助手也没法量产。
              我们希望严格把控设计质量，让每一个客户得到最好的服务。毕竟有时一个项目，对于我们是阶段性工作，而对于客户可能是毕生的事业。
            </p>
            <p>
              团队核心成员出自领域里最好的设计公司，从前期分析、研究流程，到设计手法都是国际化的。尽管我们不推崇所谓“性价比”设计，因为高品质设计一定不是最廉价的材料堆砌而成，但必须坦诚的说，相对于我们具备的设计能力水准，收取的设计费是简直是低到发指的。
            </p>
            <p>
              我们只能承诺做最好的设计，不能承诺最低的价格。如果单纯的比较“价格”，我们不会是行业里最便宜的，也奉劝对“设计品质要求平平”
              “预算有限” “随便一做”的客户理智选择其他设计公司。
            </p>
            <p>&nbsp;</p>
            <p><strong>关于道德：</strong></p>
            <p>
              团队三观狂正，对自我人格道德要求极高，诚然，“道德”和“设计能力”不存在正相关，毕竟无数艺术大师生活中简直是无耻混蛋。可这不妨碍我们向往高尚，比起奢望成为有争议的大师，我们更想以“职业设计师”的身份赢得尊重。
            </p>
          </div>
          <p>
            我们尊重每一项委任，并且持续配合跟进以致项目落地成功，并提供后期的优化与提升。我们有长期配合的装修团队与道具加工厂，但不会从中赚取任何差价。我们希望每一分设计费都对得起自己付出的设计。
          </p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>
            斗西设计一个理想主义的团队！创立至今不曾收过一分设计费之外的费用，也没有给出过一分「优良设计」之外的”好处”。我们只想用更加专业优秀的天赋和强大的内心做在腐朽当下那个闪着理想之光的团队，我们希望陌生人看到我们的作品说：那是一个漂亮的设计。而我们自己看着作品时会说：恩，好看，不光如此，那还是个内心光明的设计。
          </p>
        </rich-text>
      </div>
    </template>
    <template v-slot:content-side>
      <div class="img-content">
        <img
          src="https://oss-public.home-plan.cn/daylab/service-1.6f52f8a1.png?x-oss-process=style/w1280"
        />
      </div>
    </template>
  </basic-layout>
</template>

<script>
import BasicLayoutBody from "@/components/Layout/BasicLayoutBody";
import RichText from "@/components/RichText";

export default {
  components: {
    "basic-layout": BasicLayoutBody,
    "rich-text": RichText
  },
  data() {
    return {
      loading: false
    };
  },
  created() {},
  methods: {}
};
</script>

<style type="text/css" lang="less" scoped>
@media (max-width: 750px) {
  .service-page {
    padding: 20 / @ppr 25 / @ppr;
  }
  .img-content {
    padding: 0 25 / @ppr;
  }
}
</style>
