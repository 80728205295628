<template>
  <basic-layout>
    <template v-slot:content-main>
      <div class="contact-page">
        <rich-text>
          <p>
            邮箱/EMAIL:<br />
            应聘：hr@daylab.cn<br />
            商务：crop@daylab.cn<br />
            其他: info@daylab.cn<br />
            地址: 上海市黄浦区斜土东路176号昂云空间501<br />
            NO 176 ，Xietu Road（E）huangpu District, Shanghai<br />
            M: 15618207576<br />
            T: 021- 63307339
          </p>
          <img
            src="https://oss-public.home-plan.cn/daylab/qr-code.png"
            class="qr-code"
          />
        </rich-text>
      </div>
    </template>
    <template v-slot:content-side>
      <div class="img-content">
        <img
          src="https://oss-public.home-plan.cn/daylab/contact-address.jpg?x-oss-process=style/w1280"
        />
      </div>
    </template>
  </basic-layout>
</template>

<script>
import BasicLayoutBody from "@/components/Layout/BasicLayoutBody";
import RichText from "@/components/RichText";

export default {
  components: {
    "basic-layout": BasicLayoutBody,
    "rich-text": RichText
  },
  data() {
    return {
      loading: false
    };
  },
  created() {},
  methods: {}
};
</script>

<style type="text/css" lang="less" scoped>
.qr-code {
  transition: all 0.2s ease;
  width: 150px;
  margin-bottom: 10px;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  &:hover {
    width: 300px;
  }
}
@media (max-width: 750px) {
  .contact-page {
    padding: 20 / @ppr 25 / @ppr;
  }
  .img-content {
    padding: 0 25 / @ppr;
  }
  .qr-code {
    width: 100%;
    margin-bottom: 0;
  }
}
</style>
