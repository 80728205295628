<template>
  <footer class="page-footer">
    <div>
      ©2018 daylab.cn, All rights reserved.<br />ICP备案号：<a
        class="beian"
        href="https://beian.miit.gov.cn/"
        >沪ICP备16044170号-2</a
      ><br />
      公网安备 31010102006389
    </div>
  </footer>
</template>
<script type="text/javascript">
export default {
  data() {
    return {};
  }
};
</script>
<style type="text/css" lang="less" scoped>
.page-footer {
  color: #999999;
  font-size: 12px;
  .beian {
    color: #666;
    cursor: pointer;
  }
}
</style>
